import { FC } from 'react'
import { Text, Box, Flex, Anchor, Button } from '../primitives'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

type SectionTitleProps = {
    title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
    <Text style="subtitle1" css={{ color: '$gray12', mb: 8 }}>
        {title}
    </Text>
)

type SectionLinkProps = {
    name: string
    href: string
}

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
    <Anchor
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        weight="medium"
        css={{ fontSize: 14, mt: 16 }}
    >
        {name}
    </Anchor>
)

const developerSectionLinks = [
    {
        name: 'Docs',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'API Reference',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'Github',
        href:`${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
]

const companySectionLinks = [
    {
        name: 'Jobs',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'Terms of Use',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'Privacy Policy',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
]

export const Footer = () => {
    return (
        <Flex
            justify="center"
            css={{
                borderTop: '1px solid $gray7',
                borderStyle: 'solid',
                p: '$5',
                '@lg': {
                    p: '$6',
                },
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 36,
                '@bp600': {
                    flexDirection: 'row',
                    gap: 0,
                },
            }}
        >
            {/* <Flex css={{ gap: 80, '@bp600': { gap: 136 } }}>
        <Flex direction="column">
          <SectionTitle title="Developers" />
          {developerSectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Company" />
          {companySectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}
      >
        <SectionTitle title="Join Reservoir Community" />
        <Flex css={{ gap: '$4', mt: 16 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/xxxxxxxxxx"
          >
            <Button size="xs" color="gray3">
              <FontAwesomeIcon icon={faTwitter} width={14} height={14} />
            </Button>
          </a>
        </Flex>
      </Flex> */}
            <Flex style={{textAlign:"center", flexDirection:"column"}}>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center",fontSize:"18px", marginBottom:"5px"   } }>
                    <svg style={{marginRight: "5px"}}  className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4549" width="20" height="20">
                        <path d="M893.842 217.557H130.159c-16.261 0-29.443 13.182-29.443 29.443v530c0 16.261 13.182 29.443 29.443 29.443h763.683c16.261 0 29.443-13.183 29.443-29.443V247c0-16.261-13.182-29.443-29.443-29.443z m-85.584 58.886L512 507.651 215.742 276.443h592.516zM159.602 747.557v-440.23l334.283 260.885A29.4 29.4 0 0 0 512 574.443a29.4 29.4 0 0 0 18.115-6.231l334.283-260.884v440.229H159.602z" fill="#000000" p-id="4550"></path>
                    </svg>
                    {process.env.NEXT_PUBLIC_EMAIL}
                </div>
                <div>{process.env.NEXT_PUBLIC_RIGHTS}</div>
            </Flex>
        </Flex>
    )
}
